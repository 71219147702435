import React from "react"
// nodejs library that concatenates classes
import classNames from "classnames"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
// @material-ui/icons
// core components
import Header from "components/Header/Header.js"
import Footer from "components/Footer/Footer.js"
// import Button from "components/CustomButtons/Button.js"
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import HeaderLinks from "components/Header/HeaderLinks.js"
import NavPills from "components/NavPills/NavPills.js"
import Parallax from "components/Parallax/Parallax.js"
import Muted from "components/Typography/Muted.js"
import GetGroups from "components/Groups/Groups.jsx"

import blachford_jpg from "assets/img/blachford.jpg"

import styles from "assets/jss/material-kit-react/views/profilePage.js"

const useStyles = makeStyles(styles)

export default function ProfilePage(props) {
  const classes = useStyles()
  const { ...rest } = props
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  )
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery)
  return (
    <div>
      <Header
        color="transparent"
        brand="與菲哥追著極光跑"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/mainbg.jpg")} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
                    <img
                      src={blachford_jpg}
                      alt="..."
                      className={imageClasses}
                    />
                  </div>
                  <div className={classes.name}>
                    <h3 className={classes.title}>Blachford原野小木屋團</h3>
                    <h6>黃刀鎮，加拿大</h6>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <hr />
            <div className={classes.description}>
              <p>(blachford報名特別注意事項)</p>
            </div>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
                <NavPills
                  alignCenter
                  color="primary"
                  tabs={[
                    {
                      tabButton: "2020",
                      // tabIcon: Camera,
                      tabContent: <GetGroups groupName="Blachford" />,
                    },
                    {
                      tabButton: "2021",
                      // tabIcon: Palette,
                      tabContent: <Muted>* 2021日期預計2020三月公布 *</Muted>,
                    },
                  ]}
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
